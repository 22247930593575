import * as i0 from "@angular/core";
var StorageService = /** @class */ (function () {
    function StorageService() {
    }
    StorageService.prototype.set = function (itemName, value) {
        localStorage.setItem(itemName, value);
    };
    StorageService.prototype.get = function (itemName) {
        return localStorage.getItem(itemName);
    };
    StorageService.prototype.remove = function (itemName) {
        localStorage.removeItem(itemName);
    };
    StorageService.prototype.removeAll = function () {
        localStorage.clear();
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
