import { PipeTransform } from '@angular/core';
var ReplaceUnderscoresWithSpacePipe = /** @class */ (function () {
    function ReplaceUnderscoresWithSpacePipe() {
    }
    ReplaceUnderscoresWithSpacePipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return value ? value.replace(/_/g, ' ') : '';
    };
    return ReplaceUnderscoresWithSpacePipe;
}());
export { ReplaceUnderscoresWithSpacePipe };
