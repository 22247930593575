import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
var LoginFormComponent = /** @class */ (function () {
    function LoginFormComponent() {
        this.submitted = new EventEmitter();
        this.form = new FormGroup({
            username: new FormControl(''),
            password: new FormControl('')
        });
    }
    Object.defineProperty(LoginFormComponent.prototype, "pending", {
        set: function (isPending) {
            if (isPending) {
                this.form.disable();
            }
            else {
                this.form.enable();
            }
        },
        enumerable: true,
        configurable: true
    });
    LoginFormComponent.prototype.ngOnInit = function () {
    };
    LoginFormComponent.prototype.login = function () {
        if (this.form.valid) {
            this.submitted.emit(this.form.value);
        }
    };
    return LoginFormComponent;
}());
export { LoginFormComponent };
