import * as tslib_1 from "tslib";
import { AuthActions, AuthApiActions, LoginPageActions } from '../actions';
export var initialState = {
    user: null,
    error: null,
    pending: false
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AuthApiActions.setLoggedInUser.type:
        case AuthApiActions.loginSuccess.type: {
            return tslib_1.__assign({}, state, { user: action.user, error: null, pending: false });
        }
        case AuthActions.logout.type: {
            return initialState;
        }
        case LoginPageActions.login.type: {
            return tslib_1.__assign({}, state, { error: null, pending: true });
        }
        case AuthApiActions.loginFailure.type: {
            return tslib_1.__assign({}, state, { error: action.error, pending: false });
        }
        default: {
            return state;
        }
    }
}
export var getUser = function (state) { return state.user; };
export var getError = function (state) { return state.error; };
export var getPending = function (state) { return state.pending; };
