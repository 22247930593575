import { Routes } from '@angular/router';
import { NotFoundPageComponent } from './core/components/not-found-page/not-found-page.component';
import { AuthGuard } from './auth/services/auth-guard.service';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (mod) { return mod.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./admin/admin.module.ngfactory").then(function (mod) { return mod.AdminModuleNgFactory; }); };
export var routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'home',
        loadChildren: ɵ0,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin',
        loadChildren: ɵ1,
        canActivate: [AuthGuard]
    },
    { path: '**', component: NotFoundPageComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
