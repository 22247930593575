import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AuthActions, AuthApiActions, LoginPageActions } from '../actions';
import { LogoutConfirmationDialogComponent } from '../components/logout/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { CoreActions } from '../../core/actions';
import { AppConstants } from '../../core/constants';
import { LoginDialogComponent } from '../components/login/login-dialog/login-dialog.component';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(actions$, authService, router, dialog, storageService, authGuard) {
        var _this = this;
        this.actions$ = actions$;
        this.authService = authService;
        this.router = router;
        this.dialog = dialog;
        this.storageService = storageService;
        this.authGuard = authGuard;
        this.login$ = this.actions$.pipe(ofType(LoginPageActions.login.type, LoginPageActions.loginFromDialog), exhaustMap(function (action) {
            return _this.authService.login(action.credentials).pipe(map(function (user) {
                return action.type === LoginPageActions.loginFromDialog.type ?
                    AuthApiActions.setLoggedInUser({ user: user }) :
                    AuthApiActions.loginSuccess({ user: user });
            }), catchError(function (error) { return of(AuthApiActions.loginFailure({ error: error })); }));
        }));
        this.logout$ = this.actions$.pipe(ofType(AuthActions.logout.type), exhaustMap(function () { return _this.authService.logout().pipe(map(function () {
            _this.storageService.removeAll();
            return true;
        })); }));
        this.loginSuccess$ = this.actions$.pipe(ofType(AuthApiActions.loginSuccess.type), tap(function () {
            var redirectUrl = _this.authGuard.redirectUrl || '/';
            _this.router.navigateByUrl(redirectUrl);
        }), map(function () { return window.innerWidth > AppConstants.MOBILE_UI_MAX_WIDTH ? CoreActions.openSidenav() : CoreActions.closeSidenav(); }));
        this.loginRedirect$ = this.actions$.pipe(ofType(AuthApiActions.loginRedirect.type, AuthActions.logout.type), tap(function () {
            _this.router.navigate(['/login']);
        }), map(function () { return CoreActions.closeSidenav(); }));
        this.logoutConfirmation$ = this.actions$.pipe(ofType(AuthActions.logoutConfirmation.type), exhaustMap(function () {
            var dialogRef = _this.dialog.open(LogoutConfirmationDialogComponent, { width: '460px' });
            return dialogRef.afterClosed();
        }), map(function (result) {
            if (result) {
                return AuthActions.logout();
            }
            return AuthActions.logoutConfirmationDismiss();
        }));
        this.sessionExpired$ = this.actions$.pipe(ofType(AuthApiActions.sessionExpired.type), exhaustMap(function () {
            var dialogRef = _this.dialog.open(LoginDialogComponent);
            return dialogRef.afterClosed();
        }));
    }
    tslib_1.__decorate([
        Effect()
    ], AuthEffects.prototype, "login$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false })
    ], AuthEffects.prototype, "logout$", void 0);
    tslib_1.__decorate([
        Effect()
    ], AuthEffects.prototype, "loginSuccess$", void 0);
    tslib_1.__decorate([
        Effect()
    ], AuthEffects.prototype, "loginRedirect$", void 0);
    tslib_1.__decorate([
        Effect()
    ], AuthEffects.prototype, "logoutConfirmation$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false })
    ], AuthEffects.prototype, "sessionExpired$", void 0);
    return AuthEffects;
}());
export { AuthEffects };
