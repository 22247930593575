import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/api.service";
import * as i2 from "../../core/services/storage.service";
var AuthService = /** @class */ (function () {
    function AuthService(apiService, storageService) {
        this.apiService = apiService;
        this.storageService = storageService;
    }
    AuthService.prototype.login = function (_a) {
        var _this = this;
        var username = _a.username, password = _a.password;
        return new Observable(function (observer) {
            _this.apiService.post('auth/signin', { username: username, password: password }).subscribe(function (response) {
                if (response && response.token && response.user) {
                    _this.storageService.set('auth-token', response.token);
                    _this.storageService.set('user', JSON.stringify(response.user));
                    observer.next(response.user);
                    observer.complete();
                }
            }, function (error) {
                observer.error(error);
            });
        });
    };
    AuthService.prototype.logout = function () {
        return this.apiService.post('auth/signout', null);
    };
    AuthService.prototype.getToken = function () {
        return this.storageService.get('auth-token');
    };
    AuthService.prototype.getLoggedInUser = function () {
        var user = this.storageService.get('user');
        return user ? JSON.parse(user) : null;
    };
    AuthService.prototype.isLoggedIn = function () {
        return !!this.storageService.get('user');
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.StorageService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
