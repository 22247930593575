import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import * as fromAuth from '../../../reducers';
import { AuthApiActions, LoginPageActions } from '../../../actions';
import { BaseOnDestroy } from '../../../../core';
import { filter, takeUntil } from 'rxjs/operators';
var LoginDialogComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LoginDialogComponent, _super);
    function LoginDialogComponent(store, dialogRef, actionsSubject) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this.dialogRef = dialogRef;
        _this.actionsSubject = actionsSubject;
        _this.pending$ = _this.store.pipe(select(fromAuth.getLoginPagePending));
        _this.error$ = _this.store.pipe(select(fromAuth.getLoginPageError));
        return _this;
    }
    LoginDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actionsSubject.pipe(filter(function (action) {
            return action.type === AuthApiActions.setLoggedInUser.type;
        }), takeUntil(this.isDestroyed$))
            .subscribe(function () { return _this.dialogRef.close(); });
    };
    LoginDialogComponent.prototype.onSubmit = function (credentials) {
        this.store.dispatch(LoginPageActions.loginFromDialog({ credentials: credentials }));
    };
    return LoginDialogComponent;
}(BaseOnDestroy));
export { LoginDialogComponent };
