import { of } from 'rxjs';
import { AuthApiActions } from '../../auth/actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
import * as i3 from "./auth.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(store, router, authService) {
        this.store = store;
        this.router = router;
        this.authService = authService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        // Store the attempted URL for redirecting
        this.redirectUrl = state.url;
        if (this.authService.isLoggedIn()) {
            return of(true);
        }
        else {
            this.store.dispatch(AuthApiActions.loginRedirect());
            return of(false);
        }
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AuthService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
